import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
var DEFAULT_PERFECT_SCROLLBAR_CONFIG = {
    suppressScrollX: true
};
var ɵ0 = DEFAULT_PERFECT_SCROLLBAR_CONFIG;
var SharedModule = /** @class */ (function () {
    function SharedModule() {
    }
    return SharedModule;
}());
export { SharedModule };
export { ɵ0 };
